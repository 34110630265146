import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';
import {
  Button,
  UserSelect,
  useDialog,
  useSnackbar,
  UserSelectOption,
} from '@fdha/web-ui-library';
import {
  Coach,
  GetAllPatientsDocument,
  GetPatientUserDocument,
  ListCoachUsersDocument,
  useAssociateCoachMutation,
  useDissociateCoachMutation,
  useListCoachUsersQuery,
} from '@fdha/graphql-api-admin';

interface AssignCoachDialogProps {
  patientId: string;
  mainCoach?: Coach | null;
  backupCoach?: Coach | null;
}

export const NUMBER_OF_COACHES = 10000;

const AssignCoachDialog: FC<AssignCoachDialogProps> = ({
  patientId,
  mainCoach,
  backupCoach,
}) => {
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const [associateCoach] = useAssociateCoachMutation();
  const [dissociateCoach] = useDissociateCoachMutation();

  const isEditing = mainCoach || backupCoach;

  const { data } = useListCoachUsersQuery({
    variables: {
      first: NUMBER_OF_COACHES,
    },
    fetchPolicy: 'cache-and-network',
  });
  const nodes: UserSelectOption[] =
    data?.coachUsers.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id,
      picture: edge.node.picture ? edge.node.picture : undefined,
    })) || [];

  const initialValues = {
    mainCoach: mainCoach?.id || '',
    backupCoach: backupCoach?.id || '',
  };

  const getOptions = (
    values: { mainCoach: string; backupCoach: string },
    isMainCoach?: boolean
  ) => {
    if (isMainCoach) {
      return nodes.filter((node) => node.value !== values.backupCoach);
    }

    return nodes.filter((node) => node.value !== values.mainCoach);
  };

  const getRefetchQueries = () => [
    GetAllPatientsDocument,
    {
      query: GetPatientUserDocument,
      variables: { id: patientId },
    },
    {
      query: ListCoachUsersDocument,
      variables: { first: NUMBER_OF_COACHES },
    },
  ];

  const handleSubmit = async (values: {
    mainCoach: string;
    backupCoach: string;
  }) => {
    try {
      // Associate main coach
      if (values.mainCoach !== initialValues.mainCoach) {
        if (values.mainCoach === backupCoach?.id) {
          await dissociateCoach({
            variables: { patientId: patientId, is_main: false },
            refetchQueries: getRefetchQueries(),
          });
        }

        await associateCoach({
          variables: {
            patientId: patientId,
            coachId: values.mainCoach,
            is_main: true,
          },
          refetchQueries: getRefetchQueries(),
        });
      }

      if (values.backupCoach) {
        // Associate backup coach
        if (values.backupCoach !== initialValues.backupCoach) {
          await associateCoach({
            variables: {
              patientId: patientId,
              coachId: values.backupCoach,
              is_main: false,
            },
            refetchQueries: getRefetchQueries(),
          });
        }
      } else if (backupCoach) {
        // Dissociate backup coach
        await dissociateCoach({
          variables: { patientId: patientId, is_main: false },
          refetchQueries: getRefetchQueries(),
        });
      }
    } catch (error) {
      console.log(error);
      await dissociateCoach({
        variables: { patientId: patientId, is_main: true },
      });
      showSnackbar({ severity: 'error', message: 'Error assigning coaches' });
    } finally {
      closeDialog();
    }
  };

  const validationSchema = Yup.object().shape({
    mainCoach: Yup.string().trim().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, isValid }) => (
        <Form>
          <Box marginTop={2}>
            <UserSelect
              name="mainCoach"
              titleVariant="subtitle1"
              title="Choose main coach"
              value={values.mainCoach}
              options={getOptions(values, true)}
              onChange={handleChange}
              placeholder="Choose main coach"
              displayEmpty
            />
          </Box>
          <Box marginTop={4}>
            <UserSelect
              name="backupCoach"
              titleVariant="subtitle1"
              title="Choose backup coach"
              value={values.backupCoach}
              options={getOptions(values)}
              onChange={handleChange}
              placeholder="Choose backup coach"
              displayEmpty
              showEmptyValue
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop={4}>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid}
              sx={{ paddingY: 1, paddingX: '22px' }}
              data-testid="SAVE_BUTTON"
            >
              {isEditing ? 'Save changes' : 'Assign'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AssignCoachDialog;
