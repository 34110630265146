import React, { useCallback, useEffect, useState } from 'react';
import { useChat } from '@fdha/common-hooks';
import {
  formatDate,
  formatHour,
  HeadCell,
  SearchableTableHeader,
  Table,
} from '@fdha/web-ui-library';
import { useGetProfileQuery, UserType } from '@fdha/graphql-api-admin';
import { Paper, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
} from 'stream-chat';

import { useDebouncedValue, useFilterBy, useTable } from '../../../../hooks';
import { ChannelType } from '../../../../states/streamChannelTypeState';
import { ChatUser } from '../../../../utils';

type PendingConversation = {
  id: string;
  name: string;
  subjectId?: string;
  picture: string;
  unreadCount: number;
  lastMessageAt: string;
};

const convertChannelToPendingConversation = (
  channel: Channel
): PendingConversation => {
  const getPicture = (user?: unknown): string => {
    if (user == null) {
      return '';
    }

    const { image } = user as any;
    return typeof image === 'string' ? image : '';
  };

  const formatLastMessageAt = (lastMessageAt: Date): string => {
    const date = formatDate(lastMessageAt);
    const hour = formatHour(lastMessageAt);
    return `${date} ${hour}`;
  };

  const id = channel.id || '';
  const name = channel.data?.name || '';
  const picture = channel.data?.created_by
    ? getPicture(channel.data.created_by)
    : '';
  const unreadCount = channel.state.unreadCount;
  const lastMessageAt = channel.state.last_message_at
    ? formatLastMessageAt(channel.state.last_message_at)
    : '';
  const patient = channel.state.members[id].user as ChatUser;
  const subjectId =
    patient?.userType === UserType.ClinicalTrialPatient
      ? patient.subjectId
      : '';

  return { id, name, subjectId, picture, unreadCount, lastMessageAt };
};

const headCells: HeadCell<PendingConversation>[] = [
  {
    id: 'name',
    label: 'Patient name',
    sortable: false,
    searchable: true,
  },
  {
    id: 'subjectId',
    label: 'Subject ID',
    sortable: false,
    searchable: false,
  },
  {
    id: 'unreadCount',
    label: 'Unread count',
    sortable: false,
    searchable: false,
  },
  {
    id: 'lastMessageAt',
    label: 'Received on',
    sortable: false,
    searchable: false,
  },
];

const InboxTab = () => {
  const { client: chatClient } = useChat();

  const { data: profileData } = useGetProfileQuery();
  const [queryFilterBy, setQueryFilterBy] = useFilterBy<PendingConversation>(
    'name',
    ''
  );
  const queryFilterByDebounced = useDebouncedValue(queryFilterBy.value.trim());

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<PendingConversation[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number | undefined>();

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'inbox',
  });

  type FetchOptions = {
    page?: number;
    showLoading?: boolean;
  };

  const fetch = useCallback(
    async ({ page = 0, showLoading = true }: FetchOptions = {}) => {
      if (!chatClient || !profileData) {
        return;
      }

      setPage(page);

      if (showLoading) {
        setLoading(true);
      }

      const filter: ChannelFilters = {
        type: ChannelType.Messaging,
        members: { $in: [profileData.me.id] },
        ...(queryFilterByDebounced
          ? { name: { $autocomplete: queryFilterByDebounced } }
          : {}),
      };
      const sort: ChannelSort = [
        { unread_count: -1 as const },
        { last_message_at: -1 as const },
      ];
      const options: ChannelOptions = {
        limit: rowsPerPage + 1,
        offset: page * rowsPerPage,
      };
      const channels = await chatClient.queryChannels(filter, sort, options);

      const pendingConversations = channels
        .filter((channel) => channel.state.unreadCount)
        .map(convertChannelToPendingConversation);
      const hasMore = pendingConversations.length > rowsPerPage;

      if (page === 0) {
        setTotalRowCount(hasMore ? undefined : pendingConversations.length);
        setRows(pendingConversations);
      } else {
        setTotalRowCount(
          hasMore ? undefined : page * rowsPerPage + pendingConversations.length
        );
        setRows((rows) => {
          const newRows = [...rows];
          newRows.splice(
            page * rowsPerPage,
            pendingConversations.length,
            ...pendingConversations
          );
          return newRows;
        });
      }

      setLoading(false);
    },
    [chatClient, profileData, queryFilterByDebounced, rowsPerPage, setPage]
  );

  useEffect(() => {
    let eventListener: { unsubscribe: () => void };

    if (chatClient != null) {
      eventListener = chatClient.on((event) => {
        if (
          [
            'notification.message_new',
            'notification.mark_read',
            'message.new',
          ].includes(event.type)
        ) {
          fetch({ page, showLoading: false });
        }
      });
    }

    return () => {
      eventListener?.unsubscribe();
    };
  }, [chatClient, fetch, page, profileData]);

  useEffect(() => {
    if (chatClient) {
      fetch();
    }
  }, [chatClient, fetch, rowsPerPage, queryFilterByDebounced]);

  const onPageChange = (page: number) => {
    if (!loading) {
      fetch({ page });
    }
  };

  const emptyState =
    queryFilterByDebounced === ''
      ? `No new messages. You're up to date!`
      : undefined;

  const renderRow = (row: PendingConversation) => {
    return (
      <TableRow data-testid="TABLE_ROW" hover key={row.id}>
        <TableCell data-testid="PATIENT_NAME_CELL">{row.name}</TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subjectId}</TableCell>
        <TableCell data-testid="UNREAD_COUNT_CELL">{row.unreadCount}</TableCell>
        <TableCell data-testid="LAST_MESSAGE_AT_CELL">
          {row.lastMessageAt}
        </TableCell>
        <TableCell padding="checkbox">
          <Link data-testid="PATIENT_PROFILE_BUTTON" to={`/patient/${row.id}`}>
            <Icon
              fill={grey[600]}
              name="arrow-ios-forward-outline"
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <SearchableTableHeader<PendingConversation>
        defaultSearchField="name"
        headCells={headCells}
        onSearchChange={setQueryFilterBy}
      />
      <Paper data-testid="INBOX_TABLE">
        <Table<PendingConversation>
          actions="right"
          headCells={headCells}
          isLoading={loading}
          onPageChange={onPageChange}
          onRowsPerPageChange={changeRowsPerPage}
          page={page}
          emptyState={emptyState}
          renderRow={renderRow}
          rows={rows}
          rowsPerPage={rowsPerPage}
          totalRowCount={totalRowCount}
          withPagination
        />
      </Paper>
    </>
  );
};

export default InboxTab;
