import React from 'react';
import {
  Site,
  SiteStatus,
  SiteTrial,
  useGetSiteQuery,
  useGetSupportedLanguagesQuery,
} from '@fdha/graphql-api-admin';
import { Box, Grid, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Button, LabelBox, Loader, SelectOption } from '@fdha/web-ui-library';

import { getStatus } from '../../../../utils';

export interface SiteViewOrEditProps {
  site?: Site | null;
  languagesOptions: SelectOption[];
}

const About = () => {
  const { siteId } = useParams();

  const navigate = useNavigate();

  const { data, loading } = useGetSiteQuery({
    variables: {
      id: siteId || '',
    },
    skip: !siteId,
  });

  const site = data?.site;

  const { data: languagesData } = useGetSupportedLanguagesQuery();

  const languagesOptions = (languagesData?.supportedLanguages ?? []).map(
    (language) => ({
      label: language.nameEnglish,
      value: language.code,
    })
  ) as SelectOption[];

  const getSupportedLanguages = (siteTrial: SiteTrial) => {
    return siteTrial.languages?.length
      ? siteTrial.languages
          .map(
            (langCode) =>
              languagesOptions.find((option) => option.value === langCode)
                ?.label
          )
          .join(', ')
      : '';
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', rowGap: 4, p: 2 }}>
      <Grid container columnSpacing={4} rowSpacing={0}>
        <LabelBox size={4} label="Site name" value={site?.name} hideMargin />
        <LabelBox
          size={8}
          label="Site abbreviation"
          value={site?.abbreviation}
          hideMargin
        />
      </Grid>
      {/* Adding padding 1 to fix negative margin that material adds on grid */}
      <Box display="flex" flexDirection="column" rowGap={4} paddingLeft={1}>
        {site?.siteTrials?.map((siteTrial) => (
          <Grid
            key={siteTrial.id}
            container
            component={Paper}
            sx={{ p: 4 }}
            columnSpacing={1}
          >
            <LabelBox
              size={2}
              label="Trial"
              value={siteTrial.trial?.protocol_number}
              hideMargin
            />
            <LabelBox
              size={2}
              label="Site trial ID"
              value={siteTrial.site_identification}
              hideMargin
            />
            <LabelBox
              size={4}
              label="Supported languages"
              value={getSupportedLanguages(siteTrial)}
              hideMargin
            />
            <LabelBox
              size={3}
              label="Onboarding Part 1 scheduling"
              value={siteTrial.onboardingCallNeeded ? 'Enabled' : 'Disabled'}
              hideMargin
            />
            <LabelBox
              size={1}
              label="Status"
              value={getStatus(siteTrial.status === SiteStatus.Active)}
              hideMargin
            />
          </Grid>
        ))}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => navigate(`/trials/sites/${siteId}/edit`)}
      >
        Edit
      </Button>
    </Paper>
  );
};

export default About;
