import {
  useUpdatePatientUserMutation,
  GetPatientUserDocument,
  useSendOnboardingCallReminderMutation,
} from '@fdha/graphql-api-admin';
import { useDialog, useSnackbar } from '@fdha/web-ui-library';
import { Paper, Typography, Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { parseBackendError } from '@fdha/common-utils';

interface OnboardingReminderProps {
  patientId: string;
}

export const OnboardingReminder: FC<OnboardingReminderProps> = ({
  patientId,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [updatePatientUser] = useUpdatePatientUserMutation();
  const [sendOnboardingCallReminder] = useSendOnboardingCallReminderMutation();

  const handleUpdatePatient = async () => {
    try {
      await updatePatientUser({
        variables: {
          id: patientId,
          props: { onboardingCallScheduled: true },
        },
        refetchQueries: [GetPatientUserDocument],
      });

      showSnackbar({
        message: 'Reminder successfully dismissed.',
        severity: 'success',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to dismiss onboarding reminder',
      });
    } finally {
      closeDialog();
    }
  };

  const handleDismissReminder = () => {
    openDialog({
      title: 'Dismiss reminder?',
      content:
        'Dismissing this reminder removes it from both the Care app and the Study hub. Please make sure the subject’s Onboarding Part 1 call has been scheduled or completed before dismissing this reminder.',
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Dismiss reminder',
      handleConfirm: handleUpdatePatient,
    });
  };

  const handleSendReminderConfirmation = () => {
    openDialog({
      title: 'Remind site?',
      content:
        'The site staff will receive an email notification to schedule the subject’s Onboarding Part 1 call.',
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Send reminder',
      handleConfirm: handleSendReminder,
    });
  };

  const handleSendReminder = async () => {
    try {
      await sendOnboardingCallReminder({ variables: { patientId } });
      showSnackbar({
        message: 'Site has been notified successfully',
        severity: 'success',
      });
    } catch (error) {
      const message = parseBackendError(error, 'Error to notify the site');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  return (
    <Paper
      sx={{
        py: 2,
        px: 3,
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h6">Onboarding Part 1 call pending</Typography>
      <Typography mt={3} mb={5}>
        The site has yet to schedule the subject’s Onboarding Part 1 call. You
        can either ask them to do it, or dismiss this reminder if you already
        scheduled it yourself.
      </Typography>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button size="large" onClick={handleSendReminderConfirmation}>
          Remind Site
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleDismissReminder}
        >
          Dismiss Reminder
        </Button>
      </Stack>
    </Paper>
  );
};
