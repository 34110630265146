import { Typography, Box, styled, Paper as MuiPaper } from '@mui/material';
import React, { FC, useState } from 'react';

import ViewModeCoachCard from './ViewModeCoachCard';
import EditModeCoachCard from './EditModeCoachCard';

export interface CoachData {
  name?: string;
  email?: string;
  created_at?: string;
  phone_number?: string;
  picture?: string | null;
}

export interface CoachCardProps {
  user: CoachData;
  handleUpdateData: (data: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => Promise<void>;
  editable?: boolean;
}

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(3),
  border: 'none',
  boxShadow: theme.shadows[2],
  minWidth: '350px',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
}));

const CoachCard: FC<CoachCardProps> = ({
  user,
  handleUpdateData,
  editable = false,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Paper data-testid="COACH_PROFILE_CARD">
      <Header>
        <Typography variant="h5" data-testid="PROFILE_CARD_NAME">
          {user?.name}
        </Typography>
      </Header>
      {editable && editMode ? (
        <EditModeCoachCard
          user={user}
          handleEditMode={setEditMode}
          handleUpdateData={handleUpdateData}
        />
      ) : (
        <ViewModeCoachCard
          user={user}
          handleEditMode={setEditMode}
          editable={editable}
        />
      )}
    </Paper>
  );
};

export default CoachCard;
