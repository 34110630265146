import { useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Loader, useSnackbar } from '@fdha/web-ui-library';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from '@fdha/aws-config-admin';
import { useGetSiteStaffUserQuery } from '@fdha/graphql-api-admin';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {
  BasePage,
  ManageAccountButton,
  ProfileCard,
  SiteStaffSpecificInfo,
} from '../../components';
import { siteStaffRoleLabelByValue } from '../../utils';

import ProfileInformation from './profileInformation/ProfileInformation';

interface StateProps {
  backRoute?: string;
}

const SiteStaff = () => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const state = location.state as StateProps;
  const siteStaffId = params.siteStaffId || '';

  const { data, loading } = useGetSiteStaffUserQuery({
    variables: { id: siteStaffId },
    fetchPolicy: 'cache-and-network',
  });

  const backRouteRef = useRef(
    state?.backRoute ? state.backRoute : '/trials/site-staff-users'
  );

  const user = data?.siteStaffUser;
  const poolData = {
    UserPoolId: config.cognito.siteStaffUserPoolId,
    ClientId: config.cognito.siteStaffUserPoolClientId,
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: user?.email || '',
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  const handleResetPassword = () => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        showSnackbar({
          message: 'Reset password link sent to this user',
          severity: 'info',
        });
      },
      onFailure: (err) => {
        console.error(err.message);
        showSnackbar({
          message: 'Error to reset password',
          severity: 'error',
        });
      },
    });
  };

  return (
    <BasePage>
      <BasePage.BackButton
        handleClick={() => navigate(backRouteRef.current, { replace: true })}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Box flex={1}>
            {user && (
              <ProfileCard name={user.name} email={user.email}>
                <SiteStaffSpecificInfo
                  associatedSite={user.site.name}
                  isActive={user.is_active}
                  joinedOn={user.created_at}
                  role={siteStaffRoleLabelByValue[user.role]}
                />
              </ProfileCard>
            )}
            <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
              Manage account
            </Typography>
            <ManageAccountButton onClick={handleResetPassword}>
              <Typography
                variant="h6"
                color={theme.palette.secondary.contrastText}
              >
                Reset password
              </Typography>
            </ManageAccountButton>
          </Box>
          <Box flex={2} marginLeft={3}>
            <ProfileInformation siteStaffUser={user} />
          </Box>
        </Box>
      )}
    </BasePage>
  );
};

export default SiteStaff;
