import React, { useEffect } from 'react';
import {
  GetPatientsBySiteQuery,
  useGetPatientsBySiteQuery,
} from '@fdha/graphql-api-admin';
import {
  HeadCell,
  Icon,
  SearchableTableHeader,
  Table,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { IconButton, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getSubjectTrialStatusChip } from '../../../../utils';
import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';

type SitePatient =
  GetPatientsBySiteQuery['patientsBySite']['edges'][number]['node'];

const headCells: HeadCell<SitePatient>[] = [
  { id: 'name', label: 'Patient name', sortable: true, searchable: true },
  {
    id: 'subject_id',
    label: 'Subject ID',
    sortable: true,
    searchable: true,
  },
  {
    id: 'study_start',
    label: 'Started on study',
    sortable: true,
    searchable: false,
  },
  {
    id: 'coach:name',
    label: 'Coach',
    sortable: true,
    searchable: true,
  },
  {
    id: 'subjectTrialStatus',
    label: 'Patient status',
    sortable: false,
    searchable: false,
  },
];

const Patients = () => {
  const { siteId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { page, rowsPerPage, setPage, changeRowsPerPage } = useTable({
    key: 'sitePatients',
  });

  const [filterBy, setFilterBy] = useFilterBy<SitePatient>('subject_id', '');
  const [sortBy, setSortBy] = useSortBy<SitePatient>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading, networkStatus, fetchMore } = useGetPatientsBySiteQuery(
    {
      variables: {
        siteId: siteId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );
  const nodes = data?.patientsBySite.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.patientsBySite.pageInfo;
  const totalNumberFound = data?.patientsBySite.totalNumberFound;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No patients yet';

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const renderRow = (row: SitePatient) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PATIENT_NAME_CELL">{row.name}</TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subject_id}</TableCell>
        <TableCell data-testid="STARTED_ON_CELL">
          {formatUTCDate(row.study_start)}
        </TableCell>
        <TableCell data-testid="COACH_CELL">{row.coach?.name}</TableCell>
        <TableCell data-testid="PATIENT_STATUS_CELL">
          {getSubjectTrialStatusChip(row.subjectTrialStatus)}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton
            onClick={() =>
              navigate(`/patient/${row.id}`, {
                state: { backRoute: location.pathname },
                replace: true,
              })
            }
          >
            <Icon
              name="arrow-ios-forward-outline"
              size="large"
              fill={grey[600]}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Stack spacing={2}>
      <SearchableTableHeader<SitePatient>
        headCells={headCells}
        defaultSearchField="subject_id"
        onSearchChange={setFilterBy}
        disableMargin
      />
      <Paper>
        <Table<SitePatient>
          withPagination
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          renderRow={renderRow}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
        />
      </Paper>
    </Stack>
  );
};

export default Patients;
