import React, { useEffect } from 'react';
import {
  GetPatientsByTrialQuery,
  useGetPatientsByTrialQuery,
} from '@fdha/graphql-api-admin';
import {
  HeadCell,
  Icon,
  SearchableTableHeader,
  Table,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';

import { getSubjectTrialStatusChip } from '../../../../utils';
import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';

type TrialPatient =
  GetPatientsByTrialQuery['patientsByTrial']['edges'][number]['node'];

const headCells: HeadCell<TrialPatient>[] = [
  { id: 'name', label: 'Patient name', sortable: true, searchable: true },
  {
    id: 'subject_id',
    label: 'Subject ID',
    sortable: true,
    searchable: true,
  },
  {
    id: 'study_start',
    label: 'Started on study',
    sortable: true,
    searchable: false,
  },
  {
    id: 'site:name',
    label: 'Associated site',
    sortable: true,
    searchable: true,
  },
  {
    id: 'coach:name',
    label: 'Coach',
    sortable: true,
    searchable: true,
  },
  {
    id: 'subjectTrialStatus',
    label: 'Patient status',
    sortable: false,
    searchable: false,
  },
];

const Patients = () => {
  const { trialId = '' } = useParams();
  const { page, rowsPerPage, setPage, changeRowsPerPage } = useTable({
    key: 'trialPatients',
  });

  const [filterBy, setFilterBy] = useFilterBy<TrialPatient>('subject_id', '');
  const [sortBy, setSortBy] = useSortBy<TrialPatient>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading, networkStatus, fetchMore } =
    useGetPatientsByTrialQuery({
      variables: {
        trialId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });
  const nodes = data?.patientsByTrial.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.patientsByTrial.pageInfo;
  const totalNumberFound = data?.patientsByTrial.totalNumberFound;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No patients yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: TrialPatient) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PATIENT_NAME_CELL">{row.name}</TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subject_id}</TableCell>
        <TableCell data-testid="STARTED_ON_CELL">
          {formatUTCDate(row.study_start) || '-'}
        </TableCell>
        <TableCell data-testid="ASSOCIATED_SITE_CELL">
          {row.site?.name || '-'}
        </TableCell>
        <TableCell data-testid="COACH_CELL">{row.coach?.name || '-'}</TableCell>
        <TableCell data-testid="PATIENT_STATUS_CELL">
          {getSubjectTrialStatusChip(row.subjectTrialStatus)}
        </TableCell>
        <TableCell padding="checkbox">
          <Link
            to={`/patient/${row.id}`}
            state={{
              backRoute: `/trials/trials-list/trial/${trialId}/patients`,
            }}
            data-testid="PATIENT_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              size="large"
              fill={grey[600]}
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Stack spacing={2}>
      <SearchableTableHeader<TrialPatient>
        headCells={headCells}
        defaultSearchField="subject_id"
        onSearchChange={setFilterBy}
        disableMargin
      />
      <Paper>
        <Table<TrialPatient>
          withPagination
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          renderRow={renderRow}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
        />
      </Paper>
    </Stack>
  );
};

export default Patients;
