import React from 'react';

import { Username } from '../Username/Username';

import { Select, SelectProps } from './Select';

export interface UserSelectOption {
  label: string;
  value: string | number;
}

export type UserSelectProps = Omit<SelectProps, 'options'> & {
  options: UserSelectOption[];
};

export const UserSelect: React.FC<UserSelectProps> = (props) => {
  const renderOption = (
    value: string | number | readonly string[] | undefined
  ) => {
    const option = props.options.find((o) => o.value === value);

    return <Username name={option?.label || ''} />;
  };

  return <Select {...props} customRender={renderOption} />;
};
