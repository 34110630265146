import React, { useEffect } from 'react';
import { Loader } from '@fdha/web-ui-library';
import { ResetPassword } from '@fdha/web-auth';
import { Routes as RoutesDOM, Route, Outlet, Navigate } from 'react-router-dom';
import { useActivityDetector, useFeatureFlag } from '@fdha/common-hooks';
import { Box } from '@mui/material';
import {
  PlatformType,
  useGetProfileQuery,
  useGetUserIntercomHashKeyQuery,
} from '@fdha/graphql-api-admin';

import {
  Chat,
  Coach,
  CoachesTab,
  Settings,
  CreateNotes,
  CreateSurveys,
  CTPatientsTab,
  Data,
  DeliverySettings,
  DHPatientsTab,
  Documents,
  Folder,
  FoodOps,
  FoodOpsTab,
  Group,
  GroupsTab,
  Home,
  InboxTab,
  Learning,
  LearningPreview,
  Medical,
  Notes,
  NotesTab,
  NotesTemplatePreview,
  Patient,
  PreSignUpTab,
  Profile,
  Schedules,
  SchedulesPreview,
  Surveys,
  SurveysPreview,
  SurveyTab,
  Trials,
  PostPage,
  DataSchedules,
  AddOrEditPost,
  CustomerServiceTab,
  CommunityInitial,
  SiteStaffTab,
  SiteStaff,
  AddSiteStaff,
  SitesTab,
  AddSite,
  TrialsTab,
  Trial,
  TrialAbout,
  Site,
  SiteAbout,
  SitePatients,
  SiteSiteStaff,
  TrialSites,
  TrialPatients,
  Participants,
  Recipes,
  LanguageSelector,
  ActiveDelivery,
  WeekMenuDelivery,
  EditSite,
  AddCoachSiteTrial,
  EditCoachSiteTrial,
  CoachSiteTrialTab,
} from '../pages';
import { useGetUserType, useCommunity, useSync } from '../hooks';

const Layout = () => {
  return (
    <Box minHeight="100%" display="flex" padding="40px">
      <Outlet />
    </Box>
  );
};

function Routes() {
  const { isAdmin } = useGetUserType();
  const { isBanned } = useCommunity();
  const { init: initActivityDetector } = useActivityDetector();

  // Start listening for sync events
  useSync();

  const { isFeatureEnabled, isLoading: loadingFeatureFlags } = useFeatureFlag();

  const showBhbDataSurvey = isFeatureEnabled('show_bhb_data_survey');
  const showSiteFeatures = isFeatureEnabled('show_site_features');
  const showIntercom = isFeatureEnabled('show_intercom');
  const showStreamChat = isFeatureEnabled('show_stream_chat');
  const showDh = isFeatureEnabled('show_dh');

  const { data: profileData } = useGetProfileQuery();
  const { data: intercomHashData, loading: loadingIntercomKey } =
    useGetUserIntercomHashKeyQuery({
      variables: {
        platform: PlatformType.Web,
      },
      skip: !showIntercom,
    });

  const intercomHashKey = intercomHashData?.userIntercomHashKey;

  if (showIntercom && intercomHashKey) {
    Intercom('boot', {
      email: profileData?.me.email,
      user_id: profileData?.me.id,
      name: profileData?.me.name,
      user_hash: intercomHashKey,
    });
  }

  const isLoading =
    isAdmin === undefined || loadingFeatureFlags || loadingIntercomKey;

  useEffect(() => {
    initActivityDetector();
  }, [initActivityDetector]);

  if (isLoading) {
    return <Loader data-testid="ROUTES_LOADER" />;
  }

  return (
    <RoutesDOM>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />}>
          {showStreamChat && <Route index element={<InboxTab />} />}
          {showDh && <Route path="dh" element={<DHPatientsTab />} />}
          <Route path="ct" element={<CTPatientsTab />} />
          <Route path="groups" element={<GroupsTab />} />
          {!showStreamChat && <Route path="/" element={<Navigate to="ct" />} />}
        </Route>
        {isAdmin && (
          <>
            <Route path="trials" element={<Trials />}>
              <Route index element={<PreSignUpTab />} />
              {showSiteFeatures && (
                <>
                  <Route path="trials-list" element={<TrialsTab />} />
                  <Route path="sites">
                    <Route index element={<SitesTab />} />
                    <Route path="create-site" element={<AddSite />} />
                    <Route path=":siteId" element={<Site />}>
                      <Route index path="about" element={<SiteAbout />} />
                      <Route path="patients" element={<SitePatients />} />
                      <Route path="site-staff" element={<SiteSiteStaff />} />
                      <Route path="edit" element={<EditSite />} />
                      <Route
                        path="/trials/sites/:siteId"
                        element={<Navigate to="about" />}
                      />
                    </Route>
                  </Route>
                  <Route path="site-staff-users" element={<SiteStaffTab />} />
                </>
              )}
            </Route>
            {showSiteFeatures && (
              <>
                <Route
                  path="trials/site-staff/:siteStaffId"
                  element={<SiteStaff />}
                />
                <Route
                  path="trials/create-site-staff-user"
                  element={<AddSiteStaff />}
                />
                <Route
                  path="trials/trials-list/trial/:trialId"
                  element={<Trial />}
                >
                  <Route index element={<TrialAbout />} />
                  <Route path="patients" element={<TrialPatients />} />
                  <Route path="sites" element={<TrialSites />} />
                </Route>
                <Route
                  path="trials/trials-list/trial/:trialId/sites/:siteId"
                  element={<Site />}
                >
                  <Route index element={<SiteAbout />} />
                  <Route path="patients" element={<SitePatients />} />
                  <Route path="site-staff" element={<SiteSiteStaff />} />
                </Route>
              </>
            )}
          </>
        )}
        {showStreamChat && <Route path="chat" element={<Chat />} />}
        <Route path="community">
          <Route index element={<CommunityInitial />} />
          {isBanned ? (
            <>
              <Route path="*" element={<Navigate to="/community" />} />
            </>
          ) : (
            <>
              <Route path="post/:id" element={<PostPage />} />
              <Route path="post/:id/edit" element={<AddOrEditPost />} />
              <Route path="write-post" element={<AddOrEditPost />} />
            </>
          )}
        </Route>
        <Route path="profile" element={<Profile />} />
        <Route path="patient/:patientId/" element={<Patient />}>
          <Route index element={<Medical />} />
          <Route path="surveys">
            <Route index element={<Surveys />} />
            <Route path=":surveyId" element={<SurveysPreview />} />
          </Route>
          {showBhbDataSurvey && <Route path="data" element={<Data />} />}
          <Route path="learning" element={<Learning />} />
          <Route path="learning/:learningId" element={<LearningPreview />} />
          <Route path="notes" element={<Notes />} />
          <Route path="documents" element={<Documents />} />
          <Route path="documents/:type" element={<Folder />} />
        </Route>
        <Route
          path="patient/:patientId/language"
          element={<LanguageSelector />}
        />
        <Route
          path="patient/:patientId/delivery-settings"
          element={<DeliverySettings />}
        />
        <Route
          path="patient/:patientId/delivery/:deliveryId"
          element={<ActiveDelivery />}
        />
        <Route
          path="patient/:patientId/deliveryMenu/:deliveryMenuId"
          element={<WeekMenuDelivery />}
        />
        <Route path="patient/:patientId/surveys/schedules">
          <Route index element={<Schedules />} />
          <Route path=":surveyId" element={<SchedulesPreview />} />
        </Route>
        {showBhbDataSurvey && (
          <Route
            path="patient/:patientId/data/schedules"
            element={<DataSchedules />}
          />
        )}
        <Route path="coach">
          <Route path=":coachId" element={<Coach />}>
            <Route index element={<CoachSiteTrialTab />} />
            <Route path="assign-site-trial" element={<AddCoachSiteTrial />} />
            <Route
              path="edit-site-trial/:coachSiteTrialId"
              element={<EditCoachSiteTrial />}
            />
          </Route>
        </Route>
        <Route path="group/:groupId" element={<Group />}>
          <Route index element={<Participants />} />
          {isAdmin && <Route path="recipes" element={<Recipes />} />}
        </Route>
        <Route path="foodops/:foodopsId" element={<FoodOps />} />
        {isAdmin && (
          <>
            <Route path="settings" element={<Settings />}>
              <Route index element={<SurveyTab />} />
              <Route path="notes" element={<NotesTab />} />
              <Route path="coaches" element={<CoachesTab />} />
              <Route path="foodops-users" element={<FoodOpsTab />} />
              <Route path="csr" element={<CustomerServiceTab />} />
            </Route>
            <Route path="settings/survey">
              <Route path="create-survey" element={<CreateSurveys />} />
              <Route
                path=":surveyId"
                element={
                  <SchedulesPreview backRoute="/settings" isAssignEnabled />
                }
              />
            </Route>
            <Route path="settings/notes">
              <Route path="create-notes" element={<CreateNotes />} />
              <Route path=":surveyId" element={<NotesTemplatePreview />} />
            </Route>
          </>
        )}
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
    </RoutesDOM>
  );
}

export default Routes;
