import React, { useEffect, useMemo } from 'react';
import {
  useDialog,
  useSnackbar,
  Loader,
  WritePostSchema,
  AddOrEditPostCard,
  postValidationSchema,
} from '@fdha/web-ui-library/';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  useUpdatePostMutation,
  useAddPostMutation,
  useGetCommunityUserQuery,
  CommunityRole,
  useUploadCommunityPictureMutation,
  PostStatus,
  Post,
  useGetPostWithCommentsQuery,
} from '@fdha/graphql-api-admin';
import { uniqueId } from 'lodash';
import { useFormik } from 'formik';

import BasePage from '../../components/BasePage/BasePage';

import CommunityHeader from './CommunityHeader';
import CommunityWrapper from './CommunityWrapper';

export interface StateProps {
  backRoute?: string;
  openImagePicker: boolean;
}

// The optimistic response requires that all fields from the referenced type
// exist when adding an item, while editing required only the originally required fields
type OptimisticResponseAddPost = Required<Omit<Post, 'restoredBy'>>;
type OptimisticResponseEditPost = Omit<Post, 'removedAt' | 'restoredBy'>;

const AddOrEditPost = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as StateProps;
  const id = params.id ?? '';
  const backRoute = state?.backRoute ?? '../';
  const openImagePicker = state?.openImagePicker;
  const isFromFeed = backRoute === '/community';

  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [addPost] = useAddPostMutation();
  const [updatePost] = useUpdatePostMutation();
  const [uploadPicture] = useUploadCommunityPictureMutation();

  const { data: userData, loading: loadingUser } = useGetCommunityUserQuery();
  const { data, loading } = useGetPostWithCommentsQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const postData = data?.postWithComments?.post;

  const initialValues: WritePostSchema = useMemo(() => {
    return {
      text: postData?.text || '',
      picture: postData?.pictures?.[0] || '',
    };
  }, [postData]);

  const isEditMode = !!id;
  const user = userData?.getCommunityUser;
  const name = user?.name ?? '';
  const picture = user?.picture ?? undefined;
  const role = user?.role || CommunityRole.User;
  const isModerator = role === CommunityRole.Moderator;
  const isLoading = loading || loadingUser;

  const snackbarSuccessMsg = isEditMode
    ? 'Changes Saved'
    : 'New Post Published';

  const snackbarErrorMsg = isEditMode
    ? 'Unable to Edit Post'
    : 'Unable to Publish New Post';

  useEffect(() => {
    if (!isLoading && openImagePicker) {
      var input = document.getElementById('upload-image');
      input?.click();
    }
  }, [isLoading, openImagePicker]);

  const handleNavigationOnCancelOrPublish = () => {
    if (isFromFeed) {
      navigate(-1);
    } else {
      navigate(`/community/post/${postData?.id}`, {
        state: {},
      });
    }
  };

  const handleCancel = () => {
    openDialog({
      title: 'Are you sure you want to cancel?',
      content: 'Post will not be saved.',
      cancelButtonLabel: 'Stay',
      confirmButtonLabel: 'Discard post',
      handleConfirm: async () => {
        handleNavigationOnCancelOrPublish();
        closeDialog();
      },
    });
  };

  const handlePublish = async (values: WritePostSchema) => {
    if (user == null) {
      return;
    }

    handleNavigationOnCancelOrPublish();

    const { text, picture } = values;
    const pictures = picture !== '' ? [picture] : null;
    const postText = text.trim();

    try {
      if (isEditMode) {
        if (postData == null) {
          showSnackbar({
            severity: 'error',
            message: snackbarErrorMsg,
          });
          return;
        }

        const editPostResponse: OptimisticResponseEditPost = {
          __typename: 'Post',
          id: postData.id,
          text: postText,
          pictures,
          isEdited: true,
          isPersisted: false,
          user,
          time: postData.time,
          status: postData.status,
          removedBy: null,
          numComments: postData.numComments,
        };

        await updatePost({
          variables: {
            editPost: {
              id,
              text: postText,
              pictures,
            },
          },
          optimisticResponse: {
            updatePost: editPostResponse,
          },
          update(cache, mutationResult) {
            const post = mutationResult.data?.updatePost;

            if (post == null) {
              return;
            }

            if (!isFromFeed) {
              return;
            }

            cache.modify({
              id: cache.identify({ __typename: 'PostList' }),
              fields: {
                posts(existingPosts: Post[] = []) {
                  return existingPosts.map((p: Post) =>
                    p.id === post.id ? post : p
                  );
                },
              },
            });
          },
        });
      } else {
        const addPostResponse: OptimisticResponseAddPost = {
          __typename: 'Post',
          id: uniqueId('__ADDED_POST_'),
          user,
          time: '',
          text: postText,
          pictures,
          status: PostStatus.Created,
          removedBy: null,
          removedAt: '',
          numComments: 0,
          isEdited: false,
          isPersisted: false,
        };

        await addPost({
          variables: {
            post: {
              text: postText,
              pictures,
            },
          },
          optimisticResponse: {
            addPost: addPostResponse,
          },
          update(cache, mutationResult) {
            const post = mutationResult.data?.addPost;

            if (post == null) {
              return;
            }

            cache.modify({
              id: cache.identify({ __typename: 'PostList' }),
              fields: {
                posts(existingPosts: Post[] = []) {
                  return [post, ...existingPosts];
                },
              },
            });
          },
        });
      }

      showSnackbar({
        severity: 'success',
        message: snackbarSuccessMsg,
      });
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: snackbarErrorMsg,
      });
    }
  };

  const handleUploadPicture = async (imageFile: File) => {
    try {
      const { data } = await uploadPicture({
        variables: { picture: imageFile },
      });
      return data?.uploadCommunityPicture;
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to Upload this Picture',
      });
    }
  };

  const handleNavigateProfile = (url: string) => {
    return navigate(url);
  };

  const {
    handleSubmit,
    isValid,
    errors,
    handleChange,
    setFieldValue,
    isSubmitting,
    values,
  } = useFormik({
    initialValues,
    onSubmit: handlePublish,
    validationSchema: postValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const error = !!errors.text && errors.text !== 'required';
  const helperText = errors.text !== 'required' ? errors.text : undefined;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BasePage>
      <CommunityHeader
        name={name}
        leftItem={<BasePage.BackButton to={backRoute} />}
      />
      <CommunityWrapper>
        <AddOrEditPostCard
          error={error}
          name={name}
          role={role}
          values={values}
          picture={picture}
          isValid={isValid}
          isEditMode={isEditMode}
          isModerator={isModerator}
          isSubmitting={isSubmitting}
          helperText={helperText}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleUploadPicture={handleUploadPicture}
          handleNavigateProfile={handleNavigateProfile}
          setFieldValue={setFieldValue}
        />
      </CommunityWrapper>
    </BasePage>
  );
};

export default AddOrEditPost;
