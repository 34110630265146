import { Grid } from '@mui/material';
import { parseISO } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import {
  formatUTCDate,
  getAge,
  ProfileCardHeader,
  LabelBox,
  ProfileCardPaper,
  cancerTypes,
  getTimezoneString,
  maskPhone,
  formatDate,
  Button,
} from '@fdha/web-ui-library';
import { useGetSupportedLanguagesQuery } from '@fdha/graphql-api-admin';

import { formatProfileAttribute } from '../../utils/accountInfoData';
import { getStatusLabel } from '../../utils';

interface ProfileCardProps {
  name: string;
  subtitle?: string;
  email: string;
  phoneNumber?: string | null;
}

export const ProfileCard: React.FC<PropsWithChildren<ProfileCardProps>> = ({
  name,
  subtitle,
  email,
  phoneNumber,
  children,
}) => {
  const emailLabelBoxWidth = phoneNumber ? 6 : 12;
  return (
    <ProfileCardPaper sx={{ paddingX: 3, paddingY: 4 }}>
      <ProfileCardHeader title={name} subtitle={subtitle} />
      <Grid container spacing={4}>
        {phoneNumber && (
          <LabelBox
            size={6}
            label="Phone number"
            value={maskPhone(phoneNumber, false)}
            hideMargin
          />
        )}
        <LabelBox
          size={emailLabelBoxWidth}
          label="Email"
          value={email}
          hideMargin
        />
        {children}
      </Grid>
    </ProfileCardPaper>
  );
};

interface PatientSpecificInfoCommonProps {
  isCT?: boolean;
  birthDate?: string;
  cancerType?: string;
  diagnosisDate?: string;
  height?: number;
  weight?: number;
  joinedOn?: string;
  timezone?: string;
  restrictAccess?: boolean;
  showSiteFeatures?: boolean;
  preferredLanguage?: string;
}

type PatientSpecificInfoProps =
  | (PatientSpecificInfoCommonProps & {
      showChatButton: true;
      onChatButtonClick: () => void;
    })
  | (PatientSpecificInfoCommonProps & {
      showChatButton?: false;
      onChatButtonClick?: () => void;
    });

export const PatientSpecificInfo: React.FC<PatientSpecificInfoProps> = ({
  isCT,
  birthDate,
  cancerType,
  diagnosisDate,
  height,
  weight,
  joinedOn,
  timezone,
  restrictAccess,
  showChatButton,
  onChatButtonClick,
  showSiteFeatures,
  preferredLanguage,
}) => {
  const { data: languagesData } = useGetSupportedLanguagesQuery();

  const supportedLanguages = languagesData?.supportedLanguages;

  const getLanguageName = (language: string | undefined) => {
    return supportedLanguages?.find((lang) => lang.code === language)
      ?.nameEnglish;
  };

  return (
    <>
      {!restrictAccess && (
        <>
          <LabelBox
            size={6}
            label="Birth date"
            value={formatUTCDate(birthDate)}
            hideMargin
          />
          <LabelBox
            size={6}
            label="Age"
            value={formatProfileAttribute('AGE', getAge(birthDate))}
            hideMargin
          />
          {showSiteFeatures && isCT && (
            <>
              <LabelBox
                size={6}
                label="Weight"
                value={formatProfileAttribute('WEIGHT', weight)}
                hideMargin
              />
              <LabelBox
                size={6}
                label="Height"
                value={formatProfileAttribute('HEIGHT', height)}
                hideMargin
              />
            </>
          )}
        </>
      )}
      {((isCT && !showSiteFeatures) || !isCT) && (
        <>
          <LabelBox
            size={6}
            label="Joined on"
            value={joinedOn ? formatDate(parseISO(joinedOn)) : undefined}
            hideMargin
          />
          {!restrictAccess && (
            <>
              <LabelBox
                size={6}
                label="Cancer Diagnosis"
                value={
                  cancerTypes.find((type) => type.value === cancerType)
                    ?.label || 'Other'
                }
                hideMargin
              />
              <LabelBox
                size={6}
                label="Diagnosis date"
                value={formatUTCDate(diagnosisDate, 'monthYear')}
                hideMargin
              />
            </>
          )}
        </>
      )}
      <LabelBox
        size={6}
        label="Timezone"
        value={getTimezoneString(timezone)}
        hideMargin
      />
      {isCT && (
        <LabelBox
          size={6}
          label="Preferred language"
          value={getLanguageName(preferredLanguage)}
          hideMargin
        />
      )}

      {showChatButton && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startEvaIcon={{ name: 'message-circle-outline', size: 'large' }}
            onClick={onChatButtonClick}
          >
            go to chat
          </Button>
        </Grid>
      )}
    </>
  );
};

interface FoodOpsSpecificInfoProps {
  joinedOn?: string;
}

export const FoodOpsSpecificInfo: React.FC<FoodOpsSpecificInfoProps> = ({
  joinedOn,
}) => {
  return (
    <LabelBox
      size={6}
      label="Joined on"
      value={joinedOn ? formatDate(parseISO(joinedOn)) : undefined}
      hideMargin
    />
  );
};

interface SiteStaffSpecificInfoProps {
  associatedSite: string;
  isActive: boolean;
  joinedOn?: string;
  role: string;
}

export const SiteStaffSpecificInfo: React.FC<SiteStaffSpecificInfoProps> = ({
  associatedSite,
  isActive,
  joinedOn,
  role,
}) => {
  return (
    <>
      <LabelBox
        size={6}
        label="Joined on"
        value={joinedOn ? formatDate(parseISO(joinedOn)) : undefined}
        hideMargin
      />
      <LabelBox
        size={6}
        label="Associated site"
        value={associatedSite}
        hideMargin
      />
      <LabelBox size={6} label="Staff role" value={role} hideMargin />
      <LabelBox
        size={6}
        label="Site staff status"
        value={getStatusLabel(isActive)}
      />
    </>
  );
};
