import {
  AssignmentAvailability,
  SiteStaffRole,
  SubjectStatus,
  TrialStatus,
  Trial,
} from '@fdha/graphql-api-admin';
import { SelectOption, Option } from '@fdha/web-ui-library';
import { subjectStatusLabelByValue } from '@fdha/common-utils';

import { Chip } from '../components';

import { subjectStatusColorByValue } from './labelByValueMapper';

export const siteStaffRoleLabelByValue: Record<SiteStaffRole, string> = {
  [SiteStaffRole.Crc]: 'Clinical Research Coordinator',
  [SiteStaffRole.Pi]: 'Principle Investigator',
  [SiteStaffRole.SubI]: 'Sub-Investigator',
  [SiteStaffRole.Nurse]: 'Study Nurse',
};

export const STATUS_OPTIONS: SelectOption[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const trialStatusLabelByValue: Record<TrialStatus, string> = {
  [TrialStatus.Completed]: 'Completed',
  [TrialStatus.Recruiting]: 'Active - Enrolling',
  [TrialStatus.NotYetRecruiting]: 'Active - Not yet enrolling',
  [TrialStatus.Terminated]: 'Terminated',
  [TrialStatus.ActiveNotRecruiting]: 'Active - Not enrolling',
};

export type SubjectStatusOptions = Exclude<SubjectStatus, SubjectStatus.Draft>;

export const getStatusLabel = (isActive?: boolean) => {
  if (isActive) {
    return 'Active';
  } else {
    return 'Inactive';
  }
};

export const getStatus = (isActive: boolean) => {
  if (isActive) {
    return <Chip label="Active" color="success" />;
  } else {
    return <Chip label="Inactive" color="error" />;
  }
};

export const getTrialStatusChip = (status?: TrialStatus | null) => {
  switch (status) {
    case TrialStatus.Recruiting:
      return <Chip label="Active - Enrolling" color="success" />;
    case TrialStatus.NotYetRecruiting:
      return <Chip label="Active - Not yet enrolling" color="info" />;
    case TrialStatus.Terminated:
      return <Chip label="Terminated" color="error" />;
    case TrialStatus.Completed:
      return <Chip label="Completed" color="default" />;
    case TrialStatus.ActiveNotRecruiting:
      return <Chip label="Active - Not enrolling" color="info" />;
  }
};

export const getSubjectTrialStatusChip = (status?: SubjectStatus | null) => {
  if (status) {
    const label = subjectStatusLabelByValue[status];
    const color = subjectStatusColorByValue[status];

    return <Chip label={label} color={color} />;
  }
};

export const assignmentAvailabilityLabels: Record<
  AssignmentAvailability,
  string
> = {
  [AssignmentAvailability.Available]: 'Available',
  [AssignmentAvailability.NotAvailable]: 'Not available',
  [AssignmentAvailability.Preferred]: 'Preferred',
};

export const defaultSiteIdDigitLength = 2;

export const getSiteIDMaxLength = (
  trial: Option | null,
  trials: Trial[] | null
) => {
  if (!trial) return defaultSiteIdDigitLength;

  const selectedTrial = trials?.find((option) => option.id === trial.id);
  return selectedTrial?.siteIdDigitLength ?? defaultSiteIdDigitLength;
};

const orderedStatuses = [
  SubjectStatus.Screening,
  SubjectStatus.CoachApproved,
  SubjectStatus.Ongoing,
  SubjectStatus.StudyCompleted,
  SubjectStatus.Discontinued,
  SubjectStatus.ScreenFailed,
];

export const getSubjectStatusOptions = () => {
  const status: SelectOption[] = [];

  orderedStatuses.forEach((value) => {
    if (value !== SubjectStatus.Draft) {
      status.push({
        label: subjectStatusLabelByValue[value],
        value,
      });
    }
  });

  return status;
};
