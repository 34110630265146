import { useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Loader, useSnackbar } from '@fdha/web-ui-library';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from '@fdha/aws-config-admin';
import { useGetFoodOpsUserQuery } from '@fdha/graphql-api-admin';
import { useParams, useLocation } from 'react-router-dom';

import {
  BasePage,
  ManageAccountButton,
  ProfileCard,
  FoodOpsSpecificInfo,
} from '../../components';

import ProfileInformation from './profileInformation/ProfileInformation';

interface StateProps {
  backRoute?: string;
}

const FoodOps = () => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const params = useParams();
  const location = useLocation();
  const state = location.state as StateProps;

  const foodopsId = params.foodopsId || '';
  const { data, loading } = useGetFoodOpsUserQuery({
    variables: { id: foodopsId },
  });

  const backRouteRef = useRef(
    state ? state.backRoute : '/settings/foodops-users'
  );
  const user = data?.foodopsUser;

  const poolData = {
    UserPoolId: config.cognito.foodOpsUserPoolId,
    ClientId: config.cognito.foodOpsUserPoolClientId,
  };
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: user?.email || '',
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const handleResetPassword = () => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        showSnackbar({
          message: 'Reset password link sent to this user',
          severity: 'info',
        });
      },
      onFailure: (err) => {
        console.error(err.message);
      },
    });
  };

  return (
    <BasePage>
      <BasePage.BackButton to={backRouteRef.current} />
      {loading ? (
        <Loader />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Box flex={1}>
            {user && (
              <ProfileCard
                name={user.name}
                email={user.email}
                phoneNumber={user.phone_number}
              >
                <FoodOpsSpecificInfo joinedOn={user.created_at} />
              </ProfileCard>
            )}
            <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
              Manage account
            </Typography>
            <ManageAccountButton onClick={handleResetPassword}>
              <Typography
                variant="h6"
                color={theme.palette.secondary.contrastText}
              >
                Reset password
              </Typography>
            </ManageAccountButton>
          </Box>
          <Box flex={2} marginLeft={3}>
            <ProfileInformation foodopsUser={user} />
          </Box>
        </Box>
      )}
    </BasePage>
  );
};

export default FoodOps;
