import React from 'react';
import { TextField } from '@fdha/web-ui-library';
import { Stack } from '@mui/material';

interface CalendarConfigInputsProps {
  calendarIdValue?: string | null;
  appointmentTypeIdValue?: string | null;
  calendarIdName: string;
  appointmentTypeIdName: string;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}

const CalendarConfigInputs: React.FC<CalendarConfigInputsProps> = ({
  calendarIdValue,
  appointmentTypeIdValue,
  calendarIdName,
  appointmentTypeIdName,
  handleBlur,
  disabled,
  onChange,
}) => {
  return (
    <Stack direction="column" spacing={0.5}>
      <TextField
        title="Calendar ID"
        name={calendarIdName}
        helperText="You can find your numeric Calendar ID at the end of the URL when editing your Calendar settings."
        onChange={(event) => {
          const { name, value } = event.target;
          onChange(name, value);
        }}
        onBlur={handleBlur}
        value={calendarIdValue ?? ''}
        disabled={disabled}
        onlyNumbers
      />
      <TextField
        title="Onboarding Part 1 Appointment Type ID"
        name={appointmentTypeIdName}
        helperText="You can find your numeric Appointment Type ID at the end of the URL when editing your Appointment Type."
        onChange={(event) => {
          const { name, value } = event.target;
          onChange(name, value);
        }}
        onBlur={handleBlur}
        value={appointmentTypeIdValue ?? ''}
        disabled={disabled}
        onlyNumbers
      />
    </Stack>
  );
};

export default CalendarConfigInputs;
